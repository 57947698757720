import HTTP from '@/API/HTTP';
const API_URL = 'siswa/';
const siswa = {
  namespaced: true,
  state: {
    items: [],
    usage: [],
    item: {},
    totalRows: 0,
    isLoading: false,
    isError: false,
    totalRowsUsage: 0,
    isLoadingUsage: false,
    isErrorUsage: false,
    successMessage: '',
    errorMessage: '',
  },
  actions: {
    async getListSiswa({ commit }, state) {
      try {
        commit('setLoading', true);
        const response = await HTTP.get(API_URL, { params: state });
        commit('setItems', response.data?.data?.rows);
        commit('setRows', response.data?.data?.count);
        commit('setLoading', false);
      } catch (error) {
        commit('setRows', 0);
        commit('setLoading', false);
        commit('setError', true);
      }
    },

    async getByIDSiswa({ commit }, state) {
      try {
        commit('setLoading', true);
        const response = await HTTP.get(`${API_URL}/${state.id}`);
        commit('setItem', response.data?.data);
        commit('setLoading', false);
        return response;
      } catch (error) {
        commit('setLoading', false);
        return error;
      }
    },

    async uploadDataSiswa({ commit }, state) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      try {
        commit('setLoading', true);
        const response = await HTTP.post(API_URL, state);
        if (response.status == 201) {
          commit('setSuccessMessage', 'Data siswa berhasil ditambahkan');
        }
        commit('setLoading', false);
      } catch (error) {
        let errMsg = '';
        if (error.response?.status == 422) {
          errMsg += error.response?.data?.message;
        } else {
          errMsg += error.response.data?.data?.message;
        }
        commit('setErrorMessage', errMsg);
        commit('setLoading', false);
      }
    },

    async updateDataSiswa({ commit }, payload) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      try {
        commit('setLoading', true);
        const response = await HTTP.put(API_URL, payload);
        if (response.status == 200) {
          commit('setSuccessMessage', 'Data siswa berhasil diubah');
        }
        commit('setLoading', false);
      } catch (error) {
        const response = error.response;
        if (response?.status == 422) {
          if (response.data?.data?.errors) {
            response.data.data.errors
              .reverse()
              .map((e) => commit('setErrorMessage', e?.msg));
          } else {
            commit('setErrorMessage', response.data?.message);
          }
        } else {
          commit('setErrorMessage', response.data?.message);
        }
        commit('setLoading', false);
      }
    },
  },
  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setUsage(state, item) {
      state.usage = item;
    },
    setItem(state, item) {
      state.item = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setRowsUsage(state, data) {
      state.totalRowsUsage = data;
    },
    setLoadingUsage(state, data) {
      state.isLoadingUsage = data;
    },
    setErrorUsage(state, data) {
      state.isErrorUsage = data;
    },
    setSuccessMessage(state, data) {
      state.successMessage = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
  },
};

export default siswa;
