const Submenu = {
  path: 'submenu',
  name: 'submenu',
  component: () => import('../views/Submenu/submenu.vue'),
};

const SubmenuEdit = {
  path: 'submenu/edit/:id',
  name: 'submenu-edit',
  component: () => import('../views/Submenu/edit-submenu.vue'),
};

const SubmenuCreate = {
  path: 'submenu/create',
  name: 'submenu-create',
  component: () => import('../views/Submenu/create-submenu.vue'),
};

module.exports = {
  Submenu,
  SubmenuEdit,
  SubmenuCreate,
};
