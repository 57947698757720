const List = {
  path: '/dokumen',
  name: 'list-dokumen',
  component: () => import('../views/Dokumen/list-dokumen.vue'),
};

const uploadDocument = {
  path: '/dokumen/upload',
  name: 'upload-dokumen',
  component: () => import('../views/Dokumen/upload-dokumen.vue'),
};

const editDocument = {
  path: '/dokumen/:id',
  name: 'edit-dokumen',
  component: () => import('../views/Dokumen/edit-dokumen.vue'),
};

module.exports = {
  List,
  uploadDocument,
  editDocument,
};
