const Kelas = {
  path: '/kelas',
  name: 'kelas',
  component: () => import('../views/Kelas/list-kelas.vue'),
};
const KelasEdit = {
  path: '/kelas/edit/:id',
  name: 'kelas-edit',
  component: () => import('../views/Kelas/kelas-edit.vue'),
};
const KelasCreate = {
  path: '/kelas/create',
  name: 'kelas-create',
  component: () => import('../views/Kelas/kelas-create.vue'),
};

module.exports = {
  Kelas,
  KelasEdit,
  KelasCreate,
};
