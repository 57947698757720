const Kategori = {
  path: '/kategori-barang',
  name: 'kategori',
  component: () => import('../views/Kategori/list-kategori.vue'),
};
const kategoriEdit = {
  path: '/kategori-barang/edit/:id',
  name: 'kategori-barang-edit',
  component: () => import('../views/Kategori/kategoriEdit.vue'),
};
const kategoriCreate = {
  path: '/kategori-barang/create',
  name: 'kategori-barang-create',
  component: () => import('../views/Kategori/kategoriCreate.vue'),
};

module.exports = {
  Kategori,
  kategoriCreate,
  kategoriEdit,
};
