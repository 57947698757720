const KelasGuru = {
  path: '/kelas-guru',
  name: 'kelas-guru',
  component: () => import('../views/KelasGuru/list-kelas-guru.vue'),
};
const KelasGuruCreate = {
  path: '/kelas-guru/create',
  name: 'kelas-guru-create',
  component: () => import('../views/KelasGuru/kelas-guru-create.vue'),
};
const SubmitNilaiSiswa = {
  path: '/kelas-guru/nilai-siswa/:id',
  name: 'add-nilai-siswa',
  component: () => import('../views/KelasGuru/add-nilai-siswa.vue'),
};
const MataPelajaranGuru = {
  path: '/mata-pelajaran-guru/create',
  name: 'mata-pelajaran-guru',
  component: () => import('../views/KelasGuru/mata-pelajaran-guru.vue'),
};
const ListMataPelajaranGuru = {
  path: '/mata-pelajaran-guru',
  name: 'list-mata-pelajaran-guru',
  component: () => import('../views/KelasGuru/list-mata-pelajaran-guru.vue'),
};

module.exports = {
  KelasGuru,
  KelasGuruCreate,
  SubmitNilaiSiswa,
  MataPelajaranGuru,
  ListMataPelajaranGuru,
};
