const Merk = {
  path: '/merk',
  name: 'merk',
  component: () => import('../views/Merk/list-merk.vue'),
};
const MerkEdit = {
  path: '/merk/edit/:id',
  name: 'merk-edit',
  component: () => import('../views/Merk/merkEdit.vue'),
};
const MerkCreate = {
  path: '/merk/create',
  name: 'merk-create',
  component: () => import('../views/Merk/merkCreate.vue'),
};

module.exports = {
  Merk,
  MerkCreate,
  MerkEdit,
};
