<template>
  <div class="mt-3">
    <CFooter>
      <div class="ms-auto">
        <span class="me-1" target="_blank"> <small>Made by ❤️</small>️</span>
        <a
          href="https://www.smpn2pejawaran.sch.id/"
          target="_blank"
          style="text-decoration: none"
          ><small>SIMSEROJA | SMPN 2 Pejawaran</small></a
        >
      </div>
    </CFooter>
  </div>
</template>

<script>
export default {
  name: 'AppFooter',
};
</script>
