const List = {
  path: '/pelanggaran-siswa',
  name: 'pelanggaran-siswa',
  component: () =>
    import('../views/PelanggaranSiswa/list-pelanggaran-siswa.vue'),
};
const editNamaPelanggaran = {
  path: '/pelanggaran-siswa/edit/:id',
  name: 'pelanggaran-siswa-edit',
  component: () =>
    import('../views/PelanggaranSiswa/pelanggaran-siswa-edit.vue'),
};
const createNamaPelanggaran = {
  path: '/pelanggaran-siswa/create',
  name: 'pelanggaran-siswa-create',
  component: () =>
    import('../views/PelanggaranSiswa/pelanggaran-siswa-create.vue'),
};
const submitNamaPelanggaran = {
  path: '/pelanggaran-siswa/submit',
  name: 'pelanggaran-siswa-submit',
  component: () =>
    import('../views/PelanggaranSiswa/pelanggaran-siswa-submit.vue'),
};
const listPelanggaranSiswa = {
  path: '/pelanggaran-siswa/list',
  name: 'pelanggaran-siswa-list',
  component: () =>
    import('../views/PelanggaranSiswa/pelanggaran-siswa-list.vue'),
};
const uploadSuratPelanggaranSiswa = {
  path: '/pelanggaran-siswa/upload/:id/:name',
  name: 'pelanggaran-siswa-upload',
  component: () =>
    import('../views/PelanggaranSiswa/pelanggaran-siswa-upload.vue'),
};

module.exports = {
  List,
  editNamaPelanggaran,
  createNamaPelanggaran,
  submitNamaPelanggaran,
  listPelanggaranSiswa,
  uploadSuratPelanggaranSiswa,
};
