const listUser = {
  path: '/users',
  name: 'users',
  component: () => import('@/views/Users/list-user.vue'),
};
const editUser = {
  path: '/user/edit/:id',
  name: 'user-edit',
  component: () => import('../views/Users/edit-user.vue'),
};
const createUser = {
  path: '/user/create',
  name: 'user-create',
  component: () => import('../views/Users/create-user.vue'),
};
module.exports = {
  listUser,
  editUser,
  createUser,
};
