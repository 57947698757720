import HTTP from '@/API/HTTP';
const URL = 'master-barang';

const satuan = {
  namespaced: true,
  state: {
    items: [],
    usage: [],
    item: {},
    totalRows: 0,
    isLoading: false,
    isError: false,
    totalRowsUsage: 0,
    isLoadingUsage: false,
    isErrorUsage: false,
    successMessage: '',
    errorMessage: '',
  },
  actions: {
    async getList({ commit }, state) {
      try {
        commit('setLoading', true);
        const response = await HTTP.get(`${URL}`, { params: state });
        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
      } catch (error) {
        commit('setRows', 0);
        commit('setLoading', false);
        commit('setError', true);
      }
    },

    async getByID({ commit }, state) {
      try {
        commit('setLoading', true);
        const response = await HTTP.get(`${URL}/${state.id}`);
        commit('setItem', response.data.data);
        commit('setRows', 1);
        commit('setLoading', false);
        return response;
      } catch (error) {
        commit('setLoading', false);
        return error;
      }
    },

    async createData({ commit }, state) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      try {
        commit('setLoading', true);
        const response = await HTTP.post(`${URL}`, state);
        if (response.status == 201) {
          commit('setSuccessMessage', 'Barang berhasil ditambahkan');
        }
        commit('setLoading', false);
      } catch (error) {
        let errMsg = '';
        if (error.response?.status == 422) {
          errMsg += error.response.data.message;
        } else {
          errMsg += error.response.data.data.message;
        }
        commit('setErrorMessage', errMsg);
        commit('setLoading', false);
      }
    },

    async updateData({ commit }, payload) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      try {
        commit('setLoading', true);
        const response = await HTTP.put(`${URL}`, payload);
        if (response.status == 200) {
          commit('setSuccessMessage', 'Barang berhasil diubah');
        }
        commit('setLoading', false);
      } catch (error) {
        let errMsg = '';
        if (error.response?.status == 422) {
          errMsg += error.response?.data?.message;
        } else {
          errMsg += error.response?.data?.data?.message;
        }
        commit('setErrorMessage', errMsg);
        commit('setLoading', false);
      }
    },
  },
  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setUsage(state, item) {
      state.usage = item;
    },
    setItem(state, item) {
      state.item = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setRowsUsage(state, data) {
      state.totalRowsUsage = data;
    },
    setLoadingUsage(state, data) {
      state.isLoadingUsage = data;
    },
    setErrorUsage(state, data) {
      state.isErrorUsage = data;
    },
    setSuccessMessage(state, data) {
      state.successMessage = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
  },
};

export default satuan;
