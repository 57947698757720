const mapel = {
  path: '/mata-pelajaran',
  name: 'mata-pelajaran',
  component: () => import('../views/MataPelajaran/list-mata-pelajaran.vue'),
};
const mapelEdit = {
  path: '/mata-pelajaran/edit/:id',
  name: 'mata-pelajaran-edit',
  component: () => import('../views/MataPelajaran/mata-pelajaran-edit.vue'),
};
const mapelCreate = {
  path: '/mata-pelajaran/create',
  name: 'mata-pelajaran-create',
  component: () => import('../views/MataPelajaran/mata-pelajaran-create.vue'),
};

module.exports = {
  mapel,
  mapelCreate,
  mapelEdit,
};
