import HTTP from '@/API/HTTP';

const role = {
  namespaced: true,
  state: {
    items: [],
    usage: [],
    item: {},
    totalRows: 0,
    isLoading: false,
    isError: false,
    totalRowsUsage: 0,
    isLoadingUsage: false,
    isErrorUsage: false,
    successMessage: '',
    errorMessage: '',
  },
  getters: {
    role: (state) => state.items,
  },
  actions: {
    async listRole({ commit }, state) {
      try {
        commit('setLoading', true);
        const response = await HTTP.get(
          `roles?page=${state.page}&limit=${state.limit}`,
        );
        commit('setItems', response.data.data.rows);
        commit('setRows', response.data.data.count);
        commit('setLoading', false);
      } catch (error) {
        commit('setRows', 0);
        commit('setLoading', false);
        commit('setError', true);
      }
    },

    async getByID({ commit }, state) {
      try {
        const response = await HTTP.get(`roles/${state.id}`);
        commit('setItem', response.data.data);
        commit('setRows', 1);
        commit('setLoading', false);
        return response;
      } catch (error) {
        return error;
      }
    },

    async createRole({ commit }, state) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      try {
        commit('setLoading', true);
        const response = await HTTP.post('roles', state);
        if (response.status == 201) {
          commit('setSuccessMessage', 'Role berhasil dibuat');
        }
        commit('setLoading', false);
      } catch (error) {
        let errMsg = '';
        if (error.response.status == 422) {
          errMsg += error.response.data.message;
        } else {
          errMsg += error.response.data.data.message;
        }
        commit('setErrorMessage', errMsg);
        commit('setLoading', false);
      }
    },

    async updateRole({ commit }, payload) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      try {
        commit('setLoading', true);
        const response = await HTTP.put('roles/' + payload.id, payload);
        if (response.status == 200) {
          commit('setSuccessMessage', 'Role berhasil diubah');
        }
        commit('setLoading', false);
      } catch (error) {
        let errMsg = '';
        if (error.response.status == 422) {
          errMsg += error.response.data.message;
        } else {
          errMsg += error.response.data.data.message;
        }
        commit('setErrorMessage', errMsg);
        commit('setLoading', false);
      }
    },

    async updateUserAccess({ commit }, payload) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      try {
        const response = await HTTP.post('user-access-menu/', payload);
        if (response.status == 200 || response.status == 201) {
          commit('setSuccessMessage', 'Role Access berhasil diubah');
        }
      } catch (error) {
        let errMsg = '';
        if (error.response.status == 422) {
          errMsg += error.response.data.message;
        } else {
          errMsg += error.response.data.data.message;
        }
        commit('setErrorMessage', errMsg);
      }
    },
  },
  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setUsage(state, item) {
      state.usage = item;
    },
    setItem(state, item) {
      state.item = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setRowsUsage(state, data) {
      state.totalRowsUsage = data;
    },
    setLoadingUsage(state, data) {
      state.isLoadingUsage = data;
    },
    setErrorUsage(state, data) {
      state.isErrorUsage = data;
    },
    setSuccessMessage(state, data) {
      state.successMessage = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
  },
};

export default role;
