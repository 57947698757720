const List = {
  path: '/satuan-barang',
  name: 'satuan',
  component: () => import('../views/Satuan/list.vue'),
};
const formEdit = {
  path: '/satuan-barang/edit/:id',
  name: 'satuan-barang-edit',
  component: () => import('../views/Satuan/form-edit.vue'),
};
const formCreate = {
  path: '/satuan-barang/create',
  name: 'satuan-barang-create',
  component: () => import('../views/Satuan/form-create.vue'),
};

module.exports = {
  List,
  formCreate,
  formEdit,
};
