const ListStok = {
  path: '/stok',
  name: 'stok',
  component: () => import('../views/Stok/list-stok.vue'),
};
const stokMasuk = {
  path: '/stok/masuk',
  name: 'stok-masuk',
  component: () => import('../views/Stok/stok-masuk.vue'),
};
const stokKeluar = {
  path: '/stok/keluar',
  name: 'stok-keluar',
  component: () => import('../views/Stok/stok-keluar.vue'),
};

module.exports = {
  ListStok,
  stokMasuk,
  stokKeluar,
};
