const List = {
  path: '/lokasi-barang',
  name: 'lokasi',
  component: () => import('../views/Lokasi/list.vue'),
};
const formEdit = {
  path: '/lokasi-barang/edit/:id',
  name: 'lokasi-barang-edit',
  component: () => import('../views/Lokasi/form-edit.vue'),
};
const formCreate = {
  path: '/lokasi-barang/create',
  name: 'lokasi-barang-create',
  component: () => import('../views/Lokasi/form-create.vue'),
};

module.exports = {
  List,
  formCreate,
  formEdit,
};
