import { createApp } from 'vue';
import BootstrapVue3 from 'bootstrap-vue-3';
import App from './App.vue';
import router from './router';
import store from './store';

import CoreuiVue from '@coreui/vue';
import CIcon from '@coreui/icons-vue';
import { iconsSet as icons } from '@/assets/icons';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';
import Multiselect from '@vueform/multiselect';
import '@vueform/multiselect/themes/default.css';
import Datepicker from 'vue3-datepicker';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import VueApexCharts from 'vue3-apexcharts';
import Vue3Editor from 'vue3-editor';
import './registerServiceWorker';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

const app = createApp(App);
app.use(store);
app.use(router);
app.use(CoreuiVue);
app.provide('icons', icons);
app.component('CIcon', CIcon);
app.use(BootstrapVue3);
app.component('multiselect', Multiselect);
app.component('Datepicker', Datepicker);
app.component('VueDatePicker', VueDatePicker);
app.component('QuillEditor', QuillEditor);
app.use(VueApexCharts);
app.use(Vue3Editor);

app.mount('#app');
