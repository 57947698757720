const tahunPelajaran = {
  path: '/tahun-pelajaran',
  name: 'tahun-pelajaran',
  component: () => import('../views/TahunPelajaran/list-tahun-pelajaran.vue'),
};
const tahunPelajaranEdit = {
  path: '/tahun-pelajaran/edit/:id',
  name: 'tahun-pelajaran-edit',
  component: () => import('../views/TahunPelajaran/tahun-pelajaran-edit.vue'),
};
const tahunPelajaranCreate = {
  path: '/tahun-pelajaran/create',
  name: 'tahun-pelajaran-create',
  component: () => import('../views/TahunPelajaran/tahun-pelajaran-create.vue'),
};

module.exports = {
  tahunPelajaran,
  tahunPelajaranEdit,
  tahunPelajaranCreate,
};
