<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderBrand class="mx-auto d-lg-none" to="/">
        <img src="/assets/seroja-mobile.png" alt="SMP N 2 Pejawaran" />
      </CHeaderBrand>
      <CHeaderNav class="d-none d-md-flex me-auto"> </CHeaderNav>
      <CHeaderNav>
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
    </CContainer>
    <CHeaderDivider />
    <CContainer fluid>
      <marquee behavior="scroll" direction="left">
        <span v-html="announcement"></span>
      </marquee>
    </CContainer>
  </CHeader>
</template>

<script>
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt';
import { logo } from '@/assets/brand/logo';
import HTTP from '@/API/HTTP';
export default {
  name: 'AppHeader',
  components: {
    AppHeaderDropdownAccnt,
  },
  setup() {
    return {
      logo,
    };
  },
  data() {
    return {
      announcement: null,
    };
  },
  mounted() {
    this.getAnnouncement();
  },
  methods: {
    async getAnnouncement() {
      try {
        const { data } = await HTTP.get('/announcement');
        this.announcement = data.data?.message;
      } catch (error) {
        console.log(error?.response?.status);
      }
    },
  },
};
</script>
