import HTTP from '@/API/HTTP';
const API_URL = 'mata-pelajaran/';
const mataPelajaran = {
  namespaced: true,
  state: {
    items: [],
    usage: [],
    item: {},
    totalRows: 0,
    isLoading: false,
    isError: false,
    totalRowsUsage: 0,
    isLoadingUsage: false,
    isErrorUsage: false,
    successMessage: '',
    errorMessage: '',
  },
  actions: {
    async listMataPelajaran({ commit }, state) {
      try {
        commit('setLoading', true);
        const response = await HTTP.get(API_URL, { params: state });
        commit('setItems', response.data.data?.rows);
        commit('setRows', response.data.data?.count);
        commit('setLoading', false);
        return response.data.data?.rows;
      } catch (error) {
        commit('setRows', 0);
        commit('setLoading', false);
        commit('setError', true);
      }
    },

    async getByIdMataPelajaran({ commit }, state) {
      try {
        commit('setLoading', true);
        const response = await HTTP.get(`${API_URL}/${state.id}`);
        commit('setItem', response?.data?.data);
        commit('setRows', 1);
        commit('setLoading', false);
        return response;
      } catch (error) {
        commit('setLoading', false);
        return error;
      }
    },

    async createMataPelajaran({ commit }, state) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      try {
        commit('setLoading', true);
        const response = await HTTP.post(API_URL, state);
        if (response?.status == 201) {
          commit('setSuccessMessage', 'mata pelajaran berhasil ditambahkan');
        }
        commit('setLoading', false);
      } catch (error) {
        commit('setError', true);
        const response = error.response;
        if (response?.status == 422) {
          if (response.data?.data?.errors) {
            response?.data?.data?.errors
              .reverse()
              .map((e) => commit('setErrorMessage', e?.msg));
          } else {
            commit('setErrorMessage', response.data?.message);
          }
        } else {
          commit('setErrorMessage', response.data?.message);
        }
        commit('setLoading', false);
      }
    },

    async updateMataPelajaran({ commit }, payload) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      try {
        commit('setLoading', true);
        const response = await HTTP.put(API_URL + payload.id, payload);
        if (response.status == 200) {
          commit('setSuccessMessage', 'mata pelajaran berhasil diubah');
        }
        commit('setLoading', false);
      } catch (error) {
        commit('setError', true);
        const response = error.response;
        if (response?.status == 422) {
          if (response.data?.data?.errors) {
            response?.data?.data?.errors
              .reverse()
              .map((e) => commit('setErrorMessage', e?.msg));
          } else {
            commit('setErrorMessage', response.data?.message);
          }
        } else {
          commit('setErrorMessage', response.data?.message);
        }
        commit('setLoading', false);
      }
    },
  },
  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setUsage(state, item) {
      state.usage = item;
    },
    setItem(state, item) {
      state.item = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setRowsUsage(state, data) {
      state.totalRowsUsage = data;
    },
    setLoadingUsage(state, data) {
      state.isLoadingUsage = data;
    },
    setErrorUsage(state, data) {
      state.isErrorUsage = data;
    },
    setSuccessMessage(state, data) {
      state.successMessage = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
  },
};

export default mataPelajaran;
