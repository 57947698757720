const walikelas = {
  path: '/wali-kelas',
  name: 'wali-kelas',
  component: () => import('../views/WaliKelas/list-wali-kelas.vue'),
};
const kelasEdit = {
  path: '/wali-kelas/edit-kelas',
  name: 'wali-kelas-edit',
  component: () => import('../views/WaliKelas/wali-kelas-edit.vue'),
};
const kelasCreate = {
  path: '/wali-kelas/create',
  name: 'wali-kelas-create',
  component: () => import('../views/WaliKelas/kelas-create.vue'),
};
const addStudent = {
  path: '/wali-kelas/tambah-siswa',
  name: 'wali-kelas-create-student',
  component: () => import('../views/WaliKelas/add-student.vue'),
};
const nilaiStudent = {
  path: '/wali-kelas/nilai-siswa/:id',
  name: 'wali-kelas-nilai-student',
  component: () => import('../views/WaliKelas/value-student.vue'),
};

module.exports = {
  walikelas,
  kelasCreate,
  kelasEdit,
  addStudent,
  nilaiStudent,
};
