const laporanTransaksiList = {
  path: '/transaksi-barang',
  name: 'transaksi-barang',
  component: () =>
    import('../views/LaporanTransaksi/list-transaksi-barang.vue'),
};
const listTransaksiPending = {
  path: '/transaksi-pending',
  name: 'transaksi-pending',
  component: () =>
    import('../views/LaporanTransaksi/list-transaksi-pending.vue'),
};
module.exports = {
  laporanTransaksiList,
  listTransaksiPending,
};
