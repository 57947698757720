// import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router';

import DefaultLayout from '@/layouts/DefaultLayout';
const Role = [
  require('./role').listRole,
  require('./role').editRole,
  require('./role').createRole,
  require('./role').accessRole,
];
const User = [
  require('./user').listUser,
  require('./user').editUser,
  require('./user').createUser,
];
const Menu = [
  require('./menu').Menu,
  require('./menu').MenuEdit,
  require('./menu').MenuCreate,
];
const Submenu = [
  require('./submenu').Submenu,
  require('./submenu').SubmenuCreate,
  require('./submenu').SubmenuEdit,
];
const Surat = [
  require('./surat').Surat,
  require('./surat').SuratKeluar,
  require('./surat').suratCreate,
  require('./surat').suratCreateKeluar,
  require('./surat').suratEdit,
  require('./surat').suratEditkeluar,
];
const Merk = [
  require('./merk').Merk,
  require('./merk').MerkCreate,
  require('./merk').MerkEdit,
];
const Kategori = [
  require('./kategori').Kategori,
  require('./kategori').kategoriCreate,
  require('./kategori').kategoriEdit,
];
const Lokasi = [
  require('./lokasi').List,
  require('./lokasi').formCreate,
  require('./lokasi').formEdit,
];
const Satuan = [
  require('./satuan').List,
  require('./satuan').formCreate,
  require('./satuan').formEdit,
];
const MasterBarang = [
  require('./masterBarang').List,
  require('./masterBarang').formCreate,
  require('./masterBarang').formEdit,
];
const Stok = [
  require('./stok').ListStok,
  require('./stok').stokMasuk,
  require('./stok').stokKeluar,
];
const Tagihan = [
  require('./tagihan').List,
  require('./tagihan').formCreate,
  require('./tagihan').formEdit,
];
const Siswa = [
  require('./siswa').Siswa,
  require('./siswa').uploadSiswa,
  require('./siswa').siswaEdit,
  require('./siswa').siswaDetail,
];
const Kelas = [
  require('./kelas').Kelas,
  require('./kelas').KelasEdit,
  require('./kelas').KelasCreate,
];
const TahunPelajaran = [
  require('./tahunPelajaran').tahunPelajaran,
  require('./tahunPelajaran').tahunPelajaranCreate,
  require('./tahunPelajaran').tahunPelajaranEdit,
];
const Mapel = [
  require('./mataPelajaran').mapel,
  require('./mataPelajaran').mapelCreate,
  require('./mataPelajaran').mapelEdit,
];
const KelasGuru = [
  require('./kelasGuru').KelasGuru,
  require('./kelasGuru').KelasGuruCreate,
  require('./kelasGuru').ListMataPelajaranGuru,
  require('./kelasGuru').MataPelajaranGuru,
  require('./kelasGuru').SubmitNilaiSiswa,
];
const Aktivitas = [
  require('./aktivitas').Activity,
  require('./aktivitas').ActivityToday,
  require('./aktivitas').ActivityAdd,
  require('./aktivitas').ActivityEdit,
  require('./aktivitas').ActivityDetail,
  // ============ STAFF ============= //
  require('./aktivitas').ActivityStaff,
  require('./aktivitas').ActivityStaffAdd,
  // ============ UMUM ============= //
  require('./aktivitas').ActivityUmum,
  require('./aktivitas').ActivityUmumAdd,
  // ========== REFLEKSI =========== //
  require('./aktivitas').RefleksiHarian,
  require('./aktivitas').RefleksiHarianCreate,
  require('./aktivitas').RefleksiHarianDetail,
  require('./aktivitas').RefleksiHarianEdit,
  require('./aktivitas').RefleksiMingguan,
  require('./aktivitas').RefleksiMingguanCreate,
  require('./aktivitas').RefleksiMingguanDetail,
  require('./aktivitas').RefleksiMingguanEdit,
  require('./aktivitas').RefleksiBulanan,
  require('./aktivitas').RefleksiBulananCreate,
  require('./aktivitas').RefleksiBulananDetail,
  require('./aktivitas').RefleksiBulananEdit,
];
const WaliKelas = [
  require('./waliKelas').walikelas,
  require('./waliKelas').kelasCreate,
  require('./waliKelas').kelasEdit,
  require('./waliKelas').addStudent,
  require('./waliKelas').nilaiStudent,
];
const PermintaanBarang = [require('./permintaanBarang').List];
const AktivitasIzin = [require('./izin').Izin];
const PersetujuanBarang = [require('./persetujuanBarang').List];
const LaporanTransaksi = [
  require('./laporanTransaksi').laporanTransaksiList,
  require('./laporanTransaksi').listTransaksiPending,
];
const Dokumen = [
  require('./dokumen').List,
  require('./dokumen').uploadDocument,
  require('./dokumen').editDocument,
];
const PelanggaranSiswa = [
  require('./pelanggaranSiswa').List,
  require('./pelanggaranSiswa').createNamaPelanggaran,
  require('./pelanggaranSiswa').editNamaPelanggaran,
  require('./pelanggaranSiswa').submitNamaPelanggaran,
  require('./pelanggaranSiswa').listPelanggaranSiswa,
  require('./pelanggaranSiswa').uploadSuratPelanggaranSiswa,
];
const Notifikasi = [require('./pushNotifikasi').Notifikasi];
const AI = [require('./ai').generateTextAI, require('./ai').generateGambarAI];

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('@/views/Dashboard.vue'),
      },
      {
        path: '/profile',
        name: 'Profile',
        component: () => import('../views/pages/Profile.vue'),
      },
      ...User,
      ...Role,
      ...Menu,
      ...Submenu,
      ...Surat,
      ...Merk,
      ...Kategori,
      ...Lokasi,
      ...Satuan,
      ...MasterBarang,
      ...Stok,
      ...PermintaanBarang,
      ...PersetujuanBarang,
      ...LaporanTransaksi,
      ...Tagihan,
      ...Siswa,
      ...Kelas,
      ...TahunPelajaran,
      ...KelasGuru,
      ...Aktivitas,
      ...WaliKelas,
      ...Mapel,
      ...AktivitasIzin,
      ...Dokumen,
      ...PelanggaranSiswa,
      ...Notifikasi,
      ...AI,
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/pages/Login.vue'),
  },
  {
    path: '/icon',
    name: 'icon',
    component: () => import('@/views/icons/CoreUIIcons.vue'),
  },
  {
    path: '/brand',
    name: 'brand',
    component: () => import('@/views/icons/Brands.vue'),
  },
  {
    path: '/flag',
    name: 'flag',
    component: () => import('@/views/icons/Flags.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/pages/Page404'),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  },
});

export default router;
