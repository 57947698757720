import HTTP from '@/API/HTTP';
const API_URL = 'refleksi-harian/';
const API_URL_MINGGUAN = 'refleksi-mingguan/';
const API_URL_BULANAN = 'refleksi-bulanan/';
const refleksi = {
  namespaced: true,
  state: {
    items: [],
    usage: [],
    item: {},
    totalRows: 0,
    isLoading: false,
    isError: false,
    totalRowsUsage: 0,
    isLoadingUsage: false,
    isErrorUsage: false,
    successMessage: '',
    errorMessage: '',
  },
  actions: {
    async listRefleksiHarian({ commit }, state) {
      try {
        commit('setLoading', true);
        const response = await HTTP.get(API_URL, { params: state });
        commit('setItems', response.data?.data?.rows);
        commit('setItem', response.data?.data?.isGuru);
        commit('setRows', response.data?.data?.count);
        commit('setLoading', false);
      } catch (error) {
        commit('setRows', 0);
        commit('setLoading', false);
        commit('setError', true);
      }
    },

    async getRefleksiHarianById({ commit }, state) {
      commit('setErrorMessage', null);
      try {
        commit('setLoading', true);
        const response = await HTTP.get(API_URL + state.id);
        commit('setItem', response?.data?.data);
        commit('setLoading', false);
        return response;
      } catch (error) {
        commit('setErrorMessage', error?.response?.data?.message);
        commit('setLoading', false);
        return error;
      }
    },

    async refleksiHarianUpdate({ commit }, state) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      try {
        commit('setLoading', true);
        const response = await HTTP.put(API_URL + state.id, state);
        if (response?.status == 200) {
          commit(
            'setSuccessMessage',
            'Anda berhasil memperbarui refleksi harian',
          );
        }
        commit('setLoading', false);
      } catch (error) {
        const response = error?.response;
        if (response?.status == 422) {
          if (response.data?.data?.errors) {
            response.data?.data?.errors
              .reverse()
              .map((e) => commit('setErrorMessage', e?.msg));
          } else {
            commit('setErrorMessage', response.data?.message);
          }
        } else {
          commit('setErrorMessage', response.data?.message);
        }
        commit('setLoading', false);
      }
    },

    async refleksiHarianCreate({ commit }, payload) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      try {
        commit('setLoading', true);
        const response = await HTTP.post(API_URL, payload);
        if (response?.status == 201) {
          commit(
            'setSuccessMessage',
            'Anda berhasil menambahkan refleksi harian',
          );
        }
        commit('setLoading', false);
      } catch (error) {
        const response = error.response;
        if (response?.status == 422) {
          if (response.data?.data?.errors) {
            response.data.data?.errors
              .reverse()
              .map((e) => commit('setErrorMessage', e?.msg));
          } else {
            commit('setErrorMessage', response.data?.message);
          }
        } else {
          commit('setErrorMessage', response.data?.message);
        }
        commit('setLoading', false);
      }
    },

    // ================================== MINGGUAN ================================== //

    async listRefleksiMingguan({ commit }, state) {
      try {
        commit('setLoading', true);
        const response = await HTTP.get(API_URL_MINGGUAN, { params: state });
        commit('setItems', response.data?.data?.rows);
        commit('setRows', response.data?.data?.count);
        commit('setLoading', false);
      } catch (error) {
        commit('setRows', 0);
        commit('setLoading', false);
        commit('setError', true);
      }
    },

    async refleksiMingguanCreate({ commit }, payload) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      try {
        commit('setLoading', true);
        const response = await HTTP.post(API_URL_MINGGUAN, payload);
        if (response?.status == 201) {
          commit(
            'setSuccessMessage',
            'Anda berhasil menambahkan refleksi mingguan',
          );
        }
        commit('setLoading', false);
      } catch (error) {
        const response = error.response;
        if (response?.status == 422) {
          if (response.data?.data?.errors) {
            response.data.data?.errors
              .reverse()
              .map((e) => commit('setErrorMessage', e?.msg));
          } else {
            commit('setErrorMessage', response.data?.message);
          }
        } else {
          commit('setErrorMessage', response.data?.message);
        }
        commit('setLoading', false);
      }
    },

    async getRefleksiMingguanById({ commit }, state) {
      commit('setErrorMessage', null);
      try {
        commit('setLoading', true);
        const response = await HTTP.get(API_URL_MINGGUAN + state.id);
        commit('setItem', response?.data?.data);
        commit('setLoading', false);
        return response;
      } catch (error) {
        commit('setErrorMessage', error?.response?.data?.message);
        commit('setLoading', false);
        return error;
      }
    },

    async refleksiMingguanUpdate({ commit }, state) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      try {
        commit('setLoading', true);
        const response = await HTTP.put(API_URL_MINGGUAN + state.id, state);
        if (response?.status == 200) {
          commit(
            'setSuccessMessage',
            'Anda berhasil memperbarui refleksi mingguan',
          );
        }
        commit('setLoading', false);
      } catch (error) {
        const response = error?.response;
        if (response?.status == 422) {
          if (response.data?.data?.errors) {
            response.data?.data?.errors
              .reverse()
              .map((e) => commit('setErrorMessage', e?.msg));
          } else {
            commit('setErrorMessage', response.data?.message);
          }
        } else {
          commit('setErrorMessage', response.data?.message);
        }
        commit('setLoading', false);
      }
    },

    // ================================== BULANAN ================================== //

    async listRefleksiBulanan({ commit }, state) {
      try {
        commit('setLoading', true);
        const response = await HTTP.get(API_URL_BULANAN, { params: state });
        commit('setItems', response.data?.data?.rows);
        commit('setRows', response.data?.data?.count);
        commit('setLoading', false);
      } catch (error) {
        commit('setRows', 0);
        commit('setLoading', false);
        commit('setError', true);
      }
    },

    async refleksiBulananCreate({ commit }, payload) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      try {
        commit('setLoading', true);
        const response = await HTTP.post(API_URL_BULANAN, payload);
        if (response?.status == 201) {
          commit(
            'setSuccessMessage',
            'Anda berhasil menambahkan refleksi bulanan',
          );
        }
        commit('setLoading', false);
      } catch (error) {
        const response = error.response;
        if (response?.status == 422) {
          if (response.data?.data?.errors) {
            response.data.data?.errors
              .reverse()
              .map((e) => commit('setErrorMessage', e?.msg));
          } else {
            commit('setErrorMessage', response.data?.message);
          }
        } else {
          commit('setErrorMessage', response.data?.message);
        }
        commit('setLoading', false);
      }
    },

    async getRefleksiBulananById({ commit }, state) {
      commit('setErrorMessage', null);
      try {
        commit('setLoading', true);
        const response = await HTTP.get(API_URL_BULANAN + state.id);
        commit('setItem', response?.data?.data);
        commit('setLoading', false);
        return response;
      } catch (error) {
        commit('setErrorMessage', error?.response?.data?.message);
        commit('setLoading', false);
        return error;
      }
    },

    async refleksiBulananUpdate({ commit }, state) {
      commit('setErrorMessage', null);
      commit('setSuccessMessage', null);
      commit('setError', false);
      try {
        commit('setLoading', true);
        const response = await HTTP.put(API_URL_BULANAN + state.id, state);
        if (response?.status == 200) {
          commit(
            'setSuccessMessage',
            'Anda berhasil memperbarui refleksi bulanan',
          );
        }
        commit('setLoading', false);
      } catch (error) {
        const response = error?.response;
        if (response?.status == 422) {
          if (response.data?.data?.errors) {
            response.data?.data?.errors
              .reverse()
              .map((e) => commit('setErrorMessage', e?.msg));
          } else {
            commit('setErrorMessage', response.data?.message);
          }
        } else {
          commit('setErrorMessage', response.data?.message);
        }
        commit('setLoading', false);
      }
    },
  },
  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setUsage(state, item) {
      state.usage = item;
    },
    setItem(state, item) {
      state.item = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setRowsUsage(state, data) {
      state.totalRowsUsage = data;
    },
    setLoadingUsage(state, data) {
      state.isLoadingUsage = data;
    },
    setErrorUsage(state, data) {
      state.isErrorUsage = data;
    },
    setSuccessMessage(state, data) {
      state.successMessage = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
  },
};

export default refleksi;
