const List = {
  path: '/master-barang',
  name: 'master',
  component: () => import('../views/MasterBarang/list.vue'),
};
const formEdit = {
  path: '/master-barang/edit/:id',
  name: 'master-barang-edit',
  component: () => import('../views/MasterBarang/form-edit.vue'),
};
const formCreate = {
  path: '/master-barang/create',
  name: 'master-barang-create',
  component: () => import('../views/MasterBarang/form-create.vue'),
};

module.exports = {
  List,
  formCreate,
  formEdit,
};
