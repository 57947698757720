import HTTP from '@/API/HTTP'

const sidebar = {
  namespaced: true,
  state: {
    items: [],
    item: {},
    totalRows: 0,
    isLoading: false,
    isError: false,
    errorMsg: '',
    successMsg: '',
  },
  actions: {
    async fetchSidebar({ commit }) {
      try {
        commit('setIsLoading', true)
        commit('setIsError', false)
        const response = await HTTP.get(
          'https://new-api-denay-shop.destawp.com/products?page=1&limit=20',
        )
        commit('setItems', response.data.data.rows)
        commit('setIsLoading', false)
        return response
      } catch (error) {
        commit('setIsLoading', false)
        commit('setIsError', true)
      }
    },
  },
  mutations: {
    setItems(state, items) {
      state.items = items
    },
    setItem(state, item) {
      state.item = item
    },
    setTotalRows(state, totalRows) {
      state.totalRows = totalRows
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading
    },
    setIsError(state, isError) {
      state.isError = isError
    },
    setErrorMsg(state, errorMsg) {
      state.errorMsg = errorMsg
    },
    setSuccessMsg(state, successMsg) {
      state.successMsg = successMsg
    },
  },
}

export default sidebar
