import HTTP from '@/API/HTTP';

const dashboard = {
  namespaced: true,
  state: {
    items: [],
    usage: [],
    item: {},
    totalRows: 0,
    isLoading: false,
    isError: false,
    totalRowsUsage: 0,
    isLoadingUsage: false,
    isErrorUsage: false,
    successMessage: '',
    errorMessage: '',
  },
  actions: {
    async getStokKeluar({ commit }) {
      commit('setSuccessMessage', null);
      commit('setErrorMessage', null);
      try {
        commit('setLoading', true);
        const response = await HTTP.get(`dashboard/stok-keluar`);
        commit('setLoading', false);
        return response;
      } catch (error) {
        commit('setRows', 0);
        commit('setLoading', false);
        commit('setError', true);
        return error;
      }
    },

    async getStokMasuk({ commit }) {
      commit('setSuccessMessage', null);
      commit('setErrorMessage', null);
      try {
        commit('setLoading', true);
        const response = await HTTP.get(`dashboard/stok-masuk`);
        commit('setLoading', false);
        return response;
      } catch (error) {
        commit('setRows', 0);
        commit('setLoading', false);
        commit('setError', true);
        return error;
      }
    },
  },
  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setUsage(state, item) {
      state.usage = item;
    },
    setItem(state, item) {
      state.item = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setRowsUsage(state, data) {
      state.totalRowsUsage = data;
    },
    setLoadingUsage(state, data) {
      state.isLoadingUsage = data;
    },
    setErrorUsage(state, data) {
      state.isErrorUsage = data;
    },
    setSuccessMessage(state, data) {
      state.successMessage = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
  },
};

export default dashboard;
