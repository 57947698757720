const listRole = {
  path: '/roles',
  name: 'roles',
  component: () => import('@/views/Roles/list-role.vue'),
};
const editRole = {
  path: '/role/edit/:id',
  name: 'edit-role',
  component: () => import('@/views/Roles/edit-role.vue'),
};
const createRole = {
  path: '/role/create',
  name: 'create-role',
  component: () => import('@/views/Roles/create-role.vue'),
};
const accessRole = {
  path: '/role/access/:id',
  name: 'access-role',
  component: () => import('@/views/Roles/access-role.vue'),
};
module.exports = {
  listRole,
  editRole,
  createRole,
  accessRole,
};
