const Activity = {
  path: '/aktivitas',
  name: 'aktivitas',
  component: () => import('../views/Aktivitas/aktivitas.vue'),
};
const ActivityToday = {
  path: '/aktivitas-hari-ini',
  name: 'aktivitas-hari-ini',
  component: () => import('../views/Aktivitas/aktivitas-hari-ini.vue'),
};
const ActivityAdd = {
  path: '/aktivitas/create',
  name: 'activity',
  component: () => import('../views/Aktivitas/aktivitas-create.vue'),
};
const ActivityDetail = {
  path: '/aktivitas/detail/:id',
  name: 'activity-detail',
  component: () => import('../views/Aktivitas/aktivitas-detail.vue'),
};
const ActivityEdit = {
  path: '/aktivitas/edit/:id',
  name: 'aktivitas-edit',
  component: () => import('../views/Aktivitas/aktivitas-edit.vue'),
};

// ==================================== STAF =========================================== //
const ActivityStaff = {
  path: '/aktivitas-staff',
  name: 'aktivitas-staff',
  component: () => import('../views/Aktivitas/Staff/list-aktivitas.vue'),
};
const ActivityStaffAdd = {
  path: '/aktivitas-staff/create',
  name: 'activity-staff-add',
  component: () => import('../views/Aktivitas/Staff/aktivitas-create.vue'),
};

// ==================================== UMUM =========================================== //
const ActivityUmum = {
  path: '/aktivitas-umum',
  name: 'aktivitas-umum',
  component: () => import('../views/Aktivitas/Umum/list-aktivitas.vue'),
};
const ActivityUmumAdd = {
  path: '/aktivitas-umum/create',
  name: 'activity-umum-add',
  component: () => import('../views/Aktivitas/Umum/aktivitas-create.vue'),
};

// ================================ REFLEKSI HARIAN ==================================== //
const RefleksiHarian = {
  path: '/aktivitas/refleksi-harian',
  name: 'activity-refleksi-harian',
  component: () => import('../views/Aktivitas/Refleksi/refleksi-harian.vue'),
};
const RefleksiHarianCreate = {
  path: '/aktivitas/refleksi-harian/create',
  name: 'activity-refleksi-harian-create',
  component: () =>
    import('../views/Aktivitas/Refleksi/refleksi-harian-create.vue'),
};
const RefleksiHarianDetail = {
  path: '/aktivitas/refleksi-harian/detail/:id',
  name: 'activity-refleksi-harian-detail',
  component: () =>
    import('../views/Aktivitas/Refleksi/refleksi-harian-detail.vue'),
};
const RefleksiHarianEdit = {
  path: '/aktivitas/refleksi-harian/edit/:id',
  name: 'activity-refleksi-harian-edit',
  component: () =>
    import('../views/Aktivitas/Refleksi/refleksi-harian-edit.vue'),
};
// ================================ REFLEKSI HARIAN ==================================== //
const RefleksiMingguan = {
  path: '/aktivitas/refleksi-mingguan',
  name: 'activity-refleksi-mingguan',
  component: () => import('../views/Aktivitas/Refleksi/refleksi-mingguan.vue'),
};
const RefleksiMingguanCreate = {
  path: '/aktivitas/refleksi-mingguan/create',
  name: 'activity-refleksi-mingguan-create',
  component: () =>
    import('../views/Aktivitas/Refleksi/refleksi-mingguan-create.vue'),
};
const RefleksiMingguanDetail = {
  path: '/aktivitas/refleksi-mingguan/detail/:id',
  name: 'activity-refleksi-mingguan-detail',
  component: () =>
    import('../views/Aktivitas/Refleksi/refleksi-mingguan-detail.vue'),
};
const RefleksiMingguanEdit = {
  path: '/aktivitas/refleksi-mingguan/edit/:id',
  name: 'activity-refleksi-mingguan-edit',
  component: () =>
    import('../views/Aktivitas/Refleksi/refleksi-mingguan-edit.vue'),
};

// ================================ REFLEKSI BULANAN ==================================== //
const RefleksiBulanan = {
  path: '/aktivitas/refleksi-bulanan',
  name: 'activity-refleksi-bulanan',
  component: () => import('../views/Aktivitas/Refleksi/refleksi-bulanan.vue'),
};
const RefleksiBulananCreate = {
  path: '/aktivitas/refleksi-bulanan/create',
  name: 'activity-refleksi-bulanan-create',
  component: () =>
    import('../views/Aktivitas/Refleksi/refleksi-bulanan-create.vue'),
};
const RefleksiBulananDetail = {
  path: '/aktivitas/refleksi-bulanan/detail/:id',
  name: 'activity-refleksi-bulanan-detail',
  component: () =>
    import('../views/Aktivitas/Refleksi/refleksi-bulanan-detail.vue'),
};
const RefleksiBulananEdit = {
  path: '/aktivitas/refleksi-bulanan/edit/:id',
  name: 'activity-refleksi-bulanan-edit',
  component: () =>
    import('../views/Aktivitas/Refleksi/refleksi-bulanan-edit.vue'),
};

module.exports = {
  Activity,
  ActivityToday,
  ActivityAdd,
  ActivityDetail,
  ActivityEdit,
  // ================= STAFF ================== //
  ActivityStaff,
  ActivityStaffAdd,
  // ================= UMUM ================== //
  ActivityUmumAdd,
  ActivityUmum,
  // =============== REFLEKSI ================ //
  RefleksiHarian,
  RefleksiHarianCreate,
  RefleksiHarianDetail,
  RefleksiHarianEdit,
  RefleksiMingguan,
  RefleksiMingguanCreate,
  RefleksiMingguanDetail,
  RefleksiMingguanEdit,
  RefleksiBulanan,
  RefleksiBulananCreate,
  RefleksiBulananDetail,
  RefleksiBulananEdit,
};
