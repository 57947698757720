const Surat = {
  path: '/surat',
  name: 'surat',
  component: () => import('../views/Surat/list-surat.vue'),
};
const SuratKeluar = {
  path: '/surat-keluar',
  name: 'surat-keluar',
  component: () => import('../views/Surat/list-surat-keluar.vue'),
};
const suratEdit = {
  path: '/surat/edit/:id',
  name: 'surat-edit',
  component: () => import('../views/Surat/edit-surat.vue'),
};
const suratEditkeluar = {
  path: '/surat/keluar/edit/:id',
  name: 'surat-edit-keluar',
  component: () => import('../views/Surat/edit-surat-keluar.vue'),
};
const suratCreate = {
  path: '/surat/upload',
  name: 'surat-create',
  component: () => import('../views/Surat/create-surat.vue'),
};
const suratCreateKeluar = {
  path: '/surat/upload/keluar',
  name: 'surat-create-keluar',
  component: () => import('../views/Surat/create-surat-keluar.vue'),
};

module.exports = {
  Surat,
  SuratKeluar,
  suratCreate,
  suratCreateKeluar,
  suratEdit,
  suratEditkeluar,
};
