const Siswa = {
  path: '/siswa',
  name: 'siswa',
  component: () => import('../views/Siswa/list-siswa.vue'),
};
const uploadSiswa = {
  path: '/siswa/upload',
  name: 'siswa-upload',
  component: () => import('../views/Siswa/upload-siswa.vue'),
};
const siswaEdit = {
  path: '/siswa/edit/:id',
  name: 'siswa-edit',
  component: () => import('../views/Siswa/edit-siswa.vue'),
};
const siswaDetail = {
  path: '/siswa/detail/:id',
  name: 'siswa-detail',
  component: () => import('../views/Siswa/detail-siswa.vue'),
};

module.exports = {
  Siswa,
  uploadSiswa,
  siswaEdit,
  siswaDetail,
};
