import HTTP from '@/API/HTTP';

const profile = {
  namespaced: true,
  state: {
    items: [],
    item: {},
    itemOther: {},
    totalRows: 0,
    isLoading: false,
    isError: false,
    errorMsg: '',
    successMsg: '',
  },
  actions: {
    async getProfile({ commit }) {
      commit('setSuccessMsg', null);
      commit('setErrorMsg', null);
      try {
        commit('setIsLoading', true);
        const response = await HTTP.get('/profile');
        commit('setItem', response.data.data);
        commit('setIsLoading', false);
        return response;
      } catch (error) {
        commit('setIsLoading', false);
        if (error?.status == 403) {
          commit('setErrorMsg', "You don't have access");
          return error;
        }
        const response = error.response;
        if (response?.status == 422) {
          if (response?.data?.data?.errors) {
            response.data?.data?.errors
              .reverse()
              .map((e) => commit('setErrorMsg', e?.msg));
          } else {
            commit('setErrorMsg', response?.data?.message?.message);
          }
        } else {
          commit('setErrorMsg', response?.data?.message);
        }
        return error;
      }
    },

    async fetchTransactionID({ commit }, payload) {
      commit('setSuccessMsg', null);
      commit('setErrorMsg', null);
      try {
        commit('setIsLoading', true);
        const response = await HTTP.post(
          '/profile/passage/transactionID',
          payload,
        );
        commit('setItemOther', response.data.data);
        commit('setIsLoading', false);
        return response;
      } catch (error) {
        commit('setIsLoading', false);
        if (error?.status == 403) {
          commit('setErrorMsg', "You don't have access");
          return error;
        }
        const response = error.response;
        if (response?.status == 422) {
          if (response?.data?.data?.errors) {
            response.data?.data?.errors
              .reverse()
              .map((e) => commit('setErrorMsg', e?.msg));
          } else {
            commit('setErrorMsg', response?.data?.message?.message);
          }
        } else {
          commit('setErrorMsg', response?.data?.message);
        }
        return error;
      }
    },

    async changePassword({ commit }, payload) {
      commit('setSuccessMsg', null);
      commit('setErrorMsg', null);
      try {
        commit('setIsLoading', true);
        const response = await HTTP.post('/profile/change-password', payload);
        commit('setIsLoading', false);
        if (response.status == 200) {
          commit('setSuccessMsg', 'Password berhasil diubah');
        }
        return response;
      } catch (error) {
        commit('setIsLoading', false);
        const response = error.response;
        if (response?.status == 422) {
          if (response.data?.data?.errors) {
            response.data.data.errors
              .reverse()
              .map((e) => commit('setErrorMsg', e?.msg));
          } else {
            commit('setErrorMsg', response.data.message);
          }
        } else {
          commit('setErrorMsg', response.data.message);
        }
      }
    },

    async changeEmail({ commit }, payload) {
      commit('setSuccessMsg', null);
      commit('setErrorMsg', null);
      try {
        commit('setIsLoading', true);
        const response = await HTTP.post('/profile/change-email', payload);
        commit('setIsLoading', false);
        if (response.status == 200) {
          commit('setSuccessMsg', 'Email dan Nama berhasil diubah');
        }
        return response;
      } catch (error) {
        commit('setIsLoading', false);
        const response = error.response;
        if (response?.status == 422) {
          if (response.data?.data?.errors) {
            response.data.data.errors
              .reverse()
              .map((e) => commit('setErrorMsg', e?.msg));
          } else {
            commit('setErrorMsg', response.data.message);
          }
        } else {
          commit('setErrorMsg', response.data.message);
        }
      }
    },
  },
  mutations: {
    setItems(state, items) {
      state.items = items;
    },
    setItem(state, item) {
      state.item = item;
    },
    setItemOther(state, item) {
      state.itemOther = item;
    },
    setTotalRows(state, totalRows) {
      state.totalRows = totalRows;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setIsError(state, isError) {
      state.isError = isError;
    },
    setErrorMsg(state, errorMsg) {
      state.errorMsg = errorMsg;
    },
    setSuccessMsg(state, successMsg) {
      state.successMsg = successMsg;
    },
  },
};

export default profile;
