const Menu = {
  path: '/menu',
  name: 'menu',
  component: () => import('../views/Menu/Menu.vue'),
};
const MenuEdit = {
  path: '/menu/edit/:id',
  name: 'menu-edit',
  component: () => import('../views/Menu/MenuEdit.vue'),
};
const MenuCreate = {
  path: '/menu/create',
  name: 'menu-create',
  component: () => import('../views/Menu/MenuCreate.vue'),
};

module.exports = {
  MenuEdit,
  Menu,
  MenuCreate,
};
