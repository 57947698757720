const generateTextAI = {
  path: '/generate-text-ai',
  name: 'generate-text-ai',
  component: () => import('../views/AI/generate-text-ai.vue'),
};
const generateGambarAI = {
  path: '/generate-gambar-ai',
  name: 'generate-gambar-ai',
  component: () => import('../views/AI/generate-gambar-ai.vue'),
};

module.exports = {
  generateTextAI,
  generateGambarAI,
};
