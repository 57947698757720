<template>
  <CDropdown>
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CAvatar :src="avatar" size="md" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        Account
      </CDropdownHeader>
      <CDropdownItem class="pointer">
        <router-link :to="{ path: '/profile' }" class="routerLink text-dark">
          <CIcon icon="cil-user" /> Profil</router-link
        >
      </CDropdownItem>
      <CDropdownItem class="pointer">
        <div @click="logout"><CIcon icon="cil-lock-locked" /> Keluar</div>
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import avatar from '@/assets/images/avatars/avatar.png';
import Swal from 'sweetalert2';
export default {
  name: 'AppHeaderDropdownAccnt',
  setup() {
    return {
      avatar: avatar,
      itemsCount: 42,
    };
  },

  methods: {
    logout() {
      Swal.fire({
        title: 'Apakah anda yakin ?',
        text: 'anda akan keluar dari aplikasi',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, Logout!',
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.clear();
          window.location.reload();
        }
      });
    },
  },
};
</script>
<style scoped>
.pointer {
  cursor: pointer;
}
.routerLink {
  text-decoration: none;
}
</style>
