const List = {
  path: '/tagihan',
  name: 'tagihan',
  component: () => import('../views/Tagihan/list-tagihan.vue'),
};
const formEdit = {
  path: '/tagihan/edit/:id',
  name: 'tagihan-edit',
  component: () => import('../views/Tagihan/form-edit.vue'),
};
const formCreate = {
  path: '/tagihan/create',
  name: 'tagihan-create',
  component: () => import('../views/Tagihan/form-create.vue'),
};

module.exports = {
  List,
  formCreate,
  formEdit,
};
