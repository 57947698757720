import HTTP from './API/HTTP';

let token = localStorage.getItem('DES_session_token');
let sidebar = [];
if (token) {
  const response = await HTTP.get('/sidebar');
  sidebar = response?.data?.data;
}

export default sidebar;
